<template>
  <div>
    <b-table
      show-empty
      :responsive="true"
      :hover="true"
      :bordered="true"
      :fields="fields"
      :items="items"
      :busy="submitting"
    >
      <template #table-busy>
        <div style="height: 30px">
          <div class="h4 text-center position-loading">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Завантаження...</strong>
          </div>
        </div>
      </template>
      <template v-slot:cell(amount)="data">
        <b>{{ data.value }}</b>
      </template>
      <template v-slot:cell(original_amount)="data">
        <b>{{ data.value }}</b>
      </template>
      <template v-slot:cell(id)="data">
        <b-link
          v-if="data.item.payee_counterpart_id"
          @click="viewReceipt(data.value)"
        >
          <b-icon-printer />
        </b-link>
        {{ data.value }}
      </template>
      <template v-slot:cell(payer)="data">
        <b-link
          target="_blank"
          v-if="data.item.payer_counterpart_id"
          :to="{
            name: 'counterpart',
            params: { id: data.item.payer_counterpart_id },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>
      <template v-slot:cell(payer_fullname)="data">
        {{ data.item.payer_last_name }}
        {{ data.item.payer_first_name }}
        {{ data.item.payer_middle_name }}
      </template>
      <template v-slot:cell(payer_contract_number)="data">
        <b-link
          target="_blank"
          v-if="data.item.payer_contract_id"
          :to="{
            name: 'contract',
            params: { id: data.item.payer_contract_id },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>
      <template v-slot:cell(payee)="data">
        <b-link
          target="_blank"
          v-if="data.item.payee_counterpart_id"
          :to="{
            name: 'counterpart',
            params: { id: data.item.payee_counterpart_id },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>
      <template v-slot:cell(payee_fullname)="data">
        {{ data.item.payee_last_name }}
        {{ data.item.payee_first_name }}
        {{ data.item.payee_middle_name }}
      </template>
      <template v-slot:cell(payee_contract_number)="data">
        <b-link
          target="_blank"
          v-if="data.item.payee_contract_id"
          :to="{
            name: 'contract',
            params: { id: data.item.payee_contract_id },
          }"
        >
          {{ data.value }}
        </b-link>
        <span v-else>{{ data.value }}</span>
      </template>
      <template v-slot:cell(payer_card_hash)="data">
        <b-badge
          v-if="data.item.payer_card_hash"
          v-b-tooltip="data.item.payer_card_hash"
          :data-text="data.item.payer_card_hash"
          @click.stop.prevent="copyItem"
          class="cursor-pointer"
          variant="light"
        >
          {{ makeShortenedHash(data.item.payer_card_hash) }}
        </b-badge>
      </template>
      <template v-slot:cell(payee_card_hash)="data">
        <b-badge
          v-if="data.item.payee_card_hash"
          v-b-tooltip="data.item.payee_card_hash"
          :data-text="data.item.payee_card_hash"
          @click.stop.prevent="copyItem"
          class="cursor-pointer"
          variant="light"
        >
          {{ makeShortenedHash(data.item.payee_card_hash) }}
        </b-badge>
      </template>
      <template #empty="scope">
        <div style="height: 30px">
          <div class="h4 text-center position-loading">
            Операції не знайдено
          </div>
        </div>
      </template>
    </b-table>
    <b-modal id="receipt" size="xl" title="Квитанція" ok-only>
      <b-embed ref="receipt-src" type="embed" :src="receipt" />
    </b-modal>
  </div>
</template>

<script>
import api from "@/api/api";
import moment from "moment/moment";
import { mapGetters } from "vuex";
import {
  PERMISSION_VIEW_OPERATION_ID,
  PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID,
  PERMISSION_VIEW_OPERATION_TERMINAL_NAME,
  PERMISSION_VIEW_OPERATION_INITIATOR_IP,
  PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER,
  PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION,
  PERMISSION_VIEW_OPERATION_PAYSYSTEM_ID,
  PERMISSION_VIEW_OPERATION_BANK_TERMINAL_ID,
  PERMISSION_VIEW_OPERATION_PAYSYSTEM_NAME,
} from "@/constants";

export default {
  name: "OperationListTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      financialModelList: "financialModels/getAllFinancialModels",
      operationNbuPaysystemList: "dictionary/allOperationNbuPaysystemList",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
      contractPaymentMethodList: "dictionary/allContractPaymentMethods",
    }),
    fields: function () {
      const fields = [
        {
          key: "processed_at",
          label: "Дата",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY HH:mm:ss") : null,
          tdClass: "text-right",
        },
        { key: "payer", label: "Платник" },
        { key: "payer_card_hash", label: "Хеш картки платника" },
        { key: "payer_fullname", label: "ПІБ платника" },
        {
          key: "payer_birthday",
          label: "Дата народження отримувача",
          formatter: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
          tdClass: "text-center",
        },
        { key: "payer_code", label: "РНОКПП платника" },
        { key: "payer_passport", label: "Паспорт платника" },
        { key: "payer_contract_number", label: "Договір з платником" },
        {
          key: "payer_is_public",
          label: "Платник ПЕП",
          formatter: (v) => (v ? "Так" : "Ні"),
        },
        { key: "payee", label: "Отримувач" },
        { key: "payee_card_hash", label: "Хеш картки отримувача" },
        { key: "payee_fullname", label: "ПІБ отримувача" },
        {
          key: "payee_birthday",
          label: "Дата народження отримувача",
          formatter: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
          tdClass: "text-center",
        },
        { key: "payee_code", label: "РНОКПП отримувача" },
        { key: "payee_passport", label: "Паспорт отримувача" },
        { key: "payee_contract_number", label: "Договір з отримувачем" },
        {
          key: "payee_is_public",
          label: "Отримувач ПЕП",
          formatter: (v) => (v ? "Так" : "Ні"),
        },
        {
          key: "amount",
          label: "Сума платежу",
          formatter: (v) =>
            parseFloat(v || "0")
              .toFixed(2)
              .replace(".", ","),
          tdClass: "text-right",
        },
        {
          key: "original_amount",
          label: "Сума",
          formatter: (v) =>
            parseFloat(v || "0")
              .toFixed(2)
              .replace(".", ","),
          tdClass: "text-right",
        },
        {
          key: "fee_internal_amount",
          label: "Комісія внутрішня",
          formatter: (v) =>
            parseFloat(v || "0")
              .toFixed(2)
              .replace(".", ","),
          tdClass: "text-right",
        },
        {
          key: "fee_external_amount",
          label: "Комісія зовнішня",
          formatter: (v) =>
            parseFloat(v || "0")
              .toFixed(2)
              .replace(".", ","),
          tdClass: "text-right",
        },
        { key: "currency", label: "Валюта" },
        {
          key: "operation_type_id",
          label: "Платіжний метод",
          formatter: (v) =>
            v
              ? this.contractPaymentMethodList.find((i) => i.id === v).name
              : null,
        },
        {
          key: "received_at",
          label: "Завершення переказу",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY HH:mm:ss") : null,
        },
        {
          key: "created_at",
          label: "Дата вставки",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY HH:mm:ss") : null,
          tdClass: "text-right",
        },
        {
          key: "operation_destination_code_id",
          label: "Призначення платежу",
          formatter: (v) =>
            v
              ? this.operationDestinationCodeList.find((i) => i.id === v).name
              : null,
        },
        {
          key: "country",
          label: "Країна",
        },
      ];
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_ID))
        fields.push({ key: "id", label: "ID" });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID))
        fields.push({
          key: "financial_model_id",
          label: "Профіль СПФМ",
          formatter: (v) =>
            v ? this.financialModelList.find((i) => i.id === v).name : null,
        });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_TERMINAL_NAME))
        fields.push({ key: "terminal_name", label: "Термінал" });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_INITIATOR_IP))
        fields.push({ key: "initiator_ip", label: "IP" });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER))
        fields.push({ key: "initiator_account_number", label: "Аккаунт" });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION))
        fields.push({ key: "order_description", label: "Примітка" });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_PAYSYSTEM_NAME))
        fields.push({
          key: "nbu_paysystem_code",
          label: "Платіжна система",
          formatter: (v) =>
            v
              ? this.operationNbuPaysystemList.find((i) => i.value === v).name
              : null,
        });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_PAYSYSTEM_ID))
        fields.push({
          key: "paysystem_operation_id",
          label: "ID платіжної системи",
        });
      if (this.$auth.can(PERMISSION_VIEW_OPERATION_BANK_TERMINAL_ID))
        fields.push({
          key: "bank_terminal_id",
          label: "Банківський термінал",
        });
      return fields;
    },
  },
  data: function () {
    return {
      receipt: null,
    };
  },
  methods: {
    viewReceipt: async function (id) {
      await api
        .getOperationReceipt(id)
        .then(({ data }) => {
          this.receipt = `data:application/pdf;base64,${data}`;
          this.$root.$emit("bv::show::modal", "receipt");
        })
        .catch(({ response }) => {
          this.$snotify.error(response.data.message);
        });
    },
    copyItem(e) {
      navigator.clipboard.writeText(e.currentTarget.getAttribute("data-text"));
      this.$snotify.success("Скопійовано в буфер обміну");
    },
    makeShortenedHash(hash) {
      return hash.substring(0, 7) + ".." + hash.substring(hash.length - 2);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.position-loading {
  position: absolute;
  left: calc(50% - 125px);
  width: 250px;
}
</style>
