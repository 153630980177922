<template>
  <ValidationObserver ref="formFilterValidation" v-slot="{ handleSubmit }">
    <div v-if="Object.keys(errors).length > 0">
      <b-alert v-if="errors.code === 406" show variant="danger">
        <h4 class="alert-heading">Виникла помилка при пошуку договорів!</h4>
        <li v-for="(error, index) in errors.description" :key="index">
          <span v-for="(e, i) in error" :key="i"> {{ e }} </span>
        </li>
      </b-alert>

      <b-alert v-else show variant="danger">
        <h4 class="alert-heading">
          Виникла невідома помилка роботи серверу, спробуйте оновити сторінку
        </h4>
        <li>{{ errors.code }} - {{ errors.description }}</li>
      </b-alert>
    </div>
    <b-form>
      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="тип договору"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.type@where"
          >
            <b-form-group
              label="Тип договору"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-select
                v-model="filter.contractType.value"
                :options="types"
                value-field="value"
                text-field="name"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value=""></b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="контрагент"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.counterpart_id@where"
          >
            <b-form-group label="Контрагент" label-cols="12" label-cols-lg="3">
              <multiselect
                :value="
                  counterparts.find((o) => o.id === filter.counterpart_id.value)
                "
                @input="
                  (value) =>
                    (filter.counterpart_id.value = value ? value.id : '')
                "
                :custom-label="customCounterpartNameLabel"
                track-by="id"
                selectLabel="Обрати"
                :options="counterparts"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="id договору"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.id@where"
            :rules="{
              regex: $stringConstants('REGEX_UUID'),
            }"
          >
            <b-form-group label="ID" label-cols="12" label-cols-lg="3">
              <b-form-input
                v-model="filter.id.value"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="номер"
            :rules="{ max: 255 }"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.number@where"
          >
            <b-form-group label="Номер" label-cols="12" label-cols-lg="3">
              <b-form-input
                v-model="filter.contractNumber.value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="Дата укладання договору"
            label-cols="12"
            label-cols-lg="3"
          >
            <b-input-group>
              <b-col sm="6" class="p-0 pr-2">
                <ValidationProvider
                  name="початок періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.issued_date@gte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.issuedFrom.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Початок періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>

              <b-col sm="6" class="p-0 pl-2">
                <ValidationProvider
                  name="кінець періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.issued_date@lte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.issuedTo.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Кінець періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="статус"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.state.value@where"
          >
            <b-form-group label="Статус" label-cols="12" label-cols-lg="3">
              <b-form-select
                v-model="filter.status.value"
                :options="statuses"
                value-field="value"
                text-field="name"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value=""></b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="профіль СПФМ"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.financial_model_id@whereIn"
          >
            <b-form-group
              label="Профіль СПФМ"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.financialModel.value"
                :options="meFinancialModels.map((e) => e.id)"
                :multiple="true"
                :custom-label="customFinancialModelLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.financialModel.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customFinancialModelLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="код призначення платежу/виплати"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.contracts.destination_code_id@where"
          >
            <b-form-group
              label="Код призначення платежу/виплати"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                :value="
                  operationDestinationCodeList.find(
                    (o) => o.id === filter.destinationCode.value
                  )
                "
                @input="
                  (value) =>
                    (filter.destinationCode.value = value ? value.id : '')
                "
                :custom-label="customDestinationCodeLabel"
                track-by="id"
                :options="operationDestinationCodeList"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template
                  class="operation-destination-code-label"
                  v-slot:option="scope"
                >
                  <img
                    v-if="scope.option.ended_at"
                    src="/images/text-help.svg"
                    alt="help"
                    style="margin-right: 10px"
                    v-b-tooltip
                    :title="tooltipDestinationCode(scope.option)"
                  />
                  <template>
                    {{ customDestinationCodeLabel(scope.option) }}
                  </template>
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="псевдонім або alias"
            :rules="{ max: 255 }"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.paymentDirections.name@where"
          >
            <b-form-group
              label="Псевдонім або alias"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-input
                v-model="filter.alias.value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="видалені"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.deleted_at@withTrashed"
          >
            <b-form-group
              label="Показати видалені"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-checkbox
                name="check-button"
                switch
                size="lg"
                v-model="filter.withDeleted.value"
                value="1"
                unchecked-value="0"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import filterList from "./../structure/filter";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import debounce from "debounce";
import queryFilter from "@/shared/filterQuery";
import qs from "qs";
import mixins from "@/mixins";

export default {
  name: "filter-form",
  components: {
    Multiselect,
  },
  mixins: [mixins],
  data() {
    return {
      errors: {},
      filter: filterList,
    };
  },
  created() {
    this.debouncedFilter = debounce(this.filterSet, 500);
  },
  beforeDestroy() {
    Object.keys(this.filter).forEach((key) => {
      this.filter[key].value = "";
    });
  },
  mounted() {
    const query = Object.assign({}, this.$route.query);

    if (
      Object.keys(query).length &&
      Object.keys(query).some((key) => this.filter.hasOwnProperty(key))
    ) {
      this.$store.commit(
        "contracts/setFilter",
        queryFilter.methods.getFilterFromQuery(query, this.filter)
      );
    } else {
      this.debouncedFilter();
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(filter) {
        const newQuery = queryFilter.methods.getQueryFromFilter({}, filter);
        if (
          qs.stringify(this.$route.query) !==
          qs.stringify(newQuery, { arrayFormat: "comma" })
        ) {
          this.$router.replace({ query: newQuery });
        }

        this.$nextTick(() => {
          this.$refs.formFilterValidation.validate().then((success) => {
            if (!success) {
              this.$snotify.warning("Вказано невірний формат поля");

              return;
            }

            this.debouncedFilter();
          });
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      counterparts: "contracts/getAllCounterparts",
      meFinancialModels: "auth/meFinancialModels",
      types: "dictionary/allContractTypes",
      statuses: "dictionary/allContractStatuses",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
    }),
  },
  methods: {
    filterSet() {
      this.errors = {};
      this.$store.commit("contracts/setFilter", this.filter);
      this.$store.dispatch("contracts/setContracts").catch(({ response }) => {
        this.$set(this, "errors", response.data);

        if (response.status === 406) {
          this.$refs.formFilterValidation.setErrors(this.errors.description);
        }
      });
    },
    customCounterpartNameLabel({ name, financial_model, code }) {
      return `${name} — [${financial_model.name}] - [${code}]`;
    },
    customDestinationCodeLabel({ name, value }) {
      return `(${value}) ${name}`;
    },
    customFinancialModelLabel(id) {
      return this.meFinancialModels.find((e) => e.id === id).name;
    },
    tooltipDestinationCode($code) {
      return "Активний по " + $code.ended_at;
    },
  },
};
</script>
