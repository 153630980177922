<template>
  <div class="d-flex">
    <b-button
      class="btn-decorate"
      v-if="$auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))"
      variant="ghost-info link"
      @click="printCounterpart(counterpart.id, $stringConstants('PDF'))"
    >
      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
    </b-button>

    <b-button
      class="btn-decorate ml-2"
      v-if="$auth.can($stringConstants('PERMISSION_PRINT_COUNTERPART'))"
      variant="ghost-info link"
      @click="printCounterpart(counterpart.id, $stringConstants('DOCX'))"
    >
      <i class="fa fa-file-text-o"></i>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    counterpart: {
      type: Object,
    },
  },
  methods: {
    printCounterpart(itemId, format, date = null) {
      this.$store.dispatch("counterparts/print", [
        itemId,
        {
          format: format,
          date: date,
        },
      ]);
    },
  },
};
</script>
