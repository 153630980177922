<template>
  <div>
    <div v-if="submitting" class="d-flex justify-content-center mb-3">
      <b-spinner
        variant="success"
        type="grow"
        label="Spinning"
        style="width: 5rem; height: 5rem"
      />
    </div>
    <router-view v-else />
    <expired-token-modal v-if="!submitting" />
  </div>
</template>

<script>
import SharedWorkerHelper from "@/workers/sharedWorkerHelper";
import WorkerHelper from "@/workers/workerHelper";
import { v4 as uuidv4 } from "uuid";
import ExpiredTokenModal from "@/components/ExpiredTokenModal";

export default {
  name: "app",
  components: { SharedWorkerHelper, WorkerHelper, ExpiredTokenModal },
  data() {
    return {
      currentRefreshInterval: 60000,
      uuid: null,
      worker: null,
      submitting: true,
    };
  },
  computed: {
    haveActiveWorker() {
      return !!this.worker;
    },
    supportsSharedWorkers() {
      return !!window.SharedWorker;
    },
    supportsWebWorkers() {
      return !!window.Worker;
    },
  },
  created() {
    this.uuid = uuidv4();
    try {
      this.handleWorkerEnd();
    } catch (e) {
      // do nothing, we have no straggling workers
    }
    if (!this.worker) {
      if (this.supportsSharedWorkers) {
        this.worker = new SharedWorkerHelper(this.uuid);
      } else if (this.supportsWebWorkers) {
        this.worker = new WorkerHelper(this.uuid);
      } else {
        console.log("web workers not supported in this browser");
      }
    }

    if (this.currentRefreshInterval > 0 && this.$store.getters["auth/isAuth"]) {
      this.setupWorkerActions();
      this.handleWorkerStart();
    }

    if (
      this.$store.getters["auth/isAuth"] &&
      this.$auth.can("view financial model")
    ) {
      let p1 = this.$store
        .dispatch("financialModels/pull")
        .then((resolve) => resolve);
      let p2 = this.$store
        .dispatch("dictionary/pullContractDictionaries")
        .then((resolve) => resolve);
      let p3 = this.$store
        .dispatch("dictionary/pullCounterpartDictionaries")
        .then((resolve) => resolve);
      let p4 = this.$store
        .dispatch("contracts/pullCounterparts")
        .then((resolve) => resolve);
      let p5 = this.$store
        .dispatch("dictionary/pullTaskTypes")
        .then((resolve) => resolve);
      let p6 = this.$store
        .dispatch("dictionary/pullFinancialModelsQuestionsAnswers")
        .then((resolve) => resolve);
      let p7 = this.$store
        .dispatch("dictionary/pullSpfmCodes")
        .then((resolve) => resolve);
      let p8 = this.$store
        .dispatch("dictionary/pullH020")
        .then((resolve) => resolve);
      let p9 = this.$store
        .dispatch("dictionary/pullCountries")
        .then((resolve) => resolve);
      let p10 = this.$store
        .dispatch("dictionary/pullSpfmTypes")
        .then((resolve) => resolve);
      let p11 = this.$store
        .dispatch("dictionary/pullContractExternalTenants")
        .then((resolve) => resolve);
      let p12 = this.$store
        .dispatch("dictionary/pullNbuFileStatuses")
        .then((resolve) => resolve);
      let p13 = this.$store
        .dispatch("dictionary/pullReports")
        .then((resolve) => resolve);

      Promise.all([
        p1,
        p2,
        p3,
        p4,
        p5,
        p6,
        p7,
        p8,
        p9,
        p10,
        p11,
        p12,
        p13,
      ]).then(() => {
        this.submitting = false;
      });
    } else {
      this.submitting = false;
    }
  },
  methods: {
    async handleWorkerStart() {
      await this.setupWorkerActions();
      this.worker.on("setInterval", () => {
        this.handleWorkerUpdate("expiredToken");
      });
    },
    handleWorkerUpdate(type) {
      switch (type) {
        // eslint-disable-next-line no-case-declarations
        case "expiredToken":
          const token = window.localStorage.getItem("token");
          if (token && token !== "null") {
            const tokenTime = new Date(
              JSON.parse(window.atob(token.split(".")[1])).exp * 1000
            );
            if (tokenTime < new Date()) {
              this.$root.$emit("bv::show::modal", "expired_token_modal");
              this.handleWorkerEnd();
            }
          } else {
            this.handleWorkerEnd();
          }

          break;
        default:
          break;
      }
    },
    handleWorkerEnd() {
      if (this.worker) {
        this.worker.terminate({
          interval: this.currentRefreshInterval,
          type: "expiredToken",
          appUuid: this.uuid,
        });
        this.worker = null;
      }
    },
    setupWorkerActions() {
      this.worker.trigger("setInterval", {
        interval: this.currentRefreshInterval,
        type: "expiredToken",
        appUuid: this.uuid,
      });
    },
  },
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";
//Import css for snotify plugin
@import "~vue-snotify/styles/simple.css";
@import "~nprogress/nprogress.css";
//Form multiselect
@import "~vue-multiselect/dist/vue-multiselect.min.css";
// Import date picker css
@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
</style>
