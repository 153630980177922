import { axiosRepsInstance } from "@/api/repsApi";

export default {
  async exportContracts(route, filters) {
    return axiosRepsInstance
      .post("exports/contracts/" + route, null, { params: filters })
      .then((response) => {
        return response;
      });
  },
};
