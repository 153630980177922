import { axiosDocsInstance } from "@/api/docsApi";
import store from "@/store";
import clarification from "@/api/docs/counterparts/clarification";
import riskManagement from "@/api/docs/counterparts/riskManagement";
import axios from "axios";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const cancelAllRequests = () => {
  cancelSource.cancel();
};
const refreshToken = () => {
  cancelSource = cancelToken.source();
};

export default {
  ...clarification,
  ...riskManagement,
  counterpartVerificationVerify(form) {
    return axiosDocsInstance.post(`counterpart-verification/verify`, form);
  },
  counterpartVerificationGenerateUrl(params) {
    return axiosDocsInstance.get("counterpart-verification/generate-url", {
      params,
    });
  },
  getFinancialAssessmentByCounterpart(id) {
    return axiosDocsInstance.get(
      `counterparts/${id}/financial-assessments/create`
    );
  },
  getCriterionByCounterpart(id) {
    return axiosDocsInstance.get(`counterparts/${id}/crime-risks/create`);
  },
  createCounterpart(form) {
    return axiosDocsInstance.post("counterparts", form).then(({ data }) => {
      return data;
    });
  },
  updateCounterpart(id, form) {
    return axiosDocsInstance
      .put(`counterparts/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createCounterpartDocument(form) {
    return axiosDocsInstance
      .post(`counterparts/${form.counterpart_id}/documents`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createRepresentativeDocument(form) {
    return axiosDocsInstance
      .post(`representatives/${form.representative_id}/documents`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createPropertyCommanderDocument(form) {
    return axiosDocsInstance
      .post(`property-commanders/${form.property_commander_id}/documents`, form)
      .then(({ data }) => {
        return data;
      });
  },
  updateCounterpartDocument(id, form) {
    return axiosDocsInstance.put(`documents/${id}`, form).then(({ data }) => {
      return data;
    });
  },
  deleteCounterpartDocument(id) {
    return axiosDocsInstance.delete(`documents/${id}`).then(({ data }) => {
      return data;
    });
  },
  updateProfileMaintain(id, form) {
    return axiosDocsInstance
      .put(`counterparts/${id}/profile-maintain`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createActivityEvaluation(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/activity-evaluations`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createStateEvaluation(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/state-evaluations`, form)
      .then(({ data }) => {
        return data;
      });
  },
  updateActivityEvaluation(id, form) {
    return axiosDocsInstance
      .put(`activity-evaluations/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteActivityEvaluation(id) {
    return axiosDocsInstance
      .delete(`activity-evaluations/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  updateStateEvaluation(id, form) {
    return axiosDocsInstance
      .put(`state-evaluations/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteStateEvaluation(id) {
    return axiosDocsInstance
      .delete(`state-evaluations/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  updateRiskManagement(id, form) {
    return axiosDocsInstance
      .put(`counterparts/${id}/risk-management`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createCounterpartLicense(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/licenses`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createCrimeRisk(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/crime-risks`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createFinancialAssessment(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/financial-assessments`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteFinancialAssessment(id) {
    return axiosDocsInstance
      .delete(`counterparts/financial-assessments/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  updateClarification(id, form) {
    return axiosDocsInstance
      .put(`counterparts/${id}/clarification`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createPropertyCommander(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/property-commanders`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createRepresentative(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/representatives`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createSeparateUnit(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/separate-units`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteSeparateUnits(id) {
    return axiosDocsInstance
      .delete(`counterparts/${id}/separate-units/`)
      .then(({ data }) => {
        return data;
      });
  },
  createParentCompany(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/parent-companies`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteParentCompanies(id) {
    return axiosDocsInstance
      .delete(`counterparts/${id}/parent-companies/`)
      .then(({ data }) => {
        return data;
      });
  },
  createOwner(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/owners`, form)
      .then(({ data }) => {
        return data;
      });
  },
  createBeneficialOwner(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/beneficial-owners`, form)
      .then(({ data }) => {
        return data;
      });
  },
  getCounterpartById(id) {
    return axiosDocsInstance.get("counterparts/" + id).then((response) => {
      return response.data;
    });
  },
  removeCounterpart(id) {
    return axiosDocsInstance.delete("counterparts/" + id).then((response) => {
      return response.data;
    });
  },
  restoreCounterpart(id) {
    return axiosDocsInstance
      .post(`counterparts/${id}/restore`)
      .then(({ data }) => {
        return data;
      });
  },
  createNewContractItem(id, item) {
    return axiosDocsInstance
      .post(`counterparts/${id}/contracts`, item)
      .then((response) => {
        return response.data;
      });
  },
  createVerification(id, form) {
    return axiosDocsInstance
      .post(`counterparts/${id}/verifications`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteVerification(id) {
    return axiosDocsInstance.delete(`verifications/${id}`).then(({ data }) => {
      return data;
    });
  },
  getCounterpartItems(params = {}) {
    cancelAllRequests();
    refreshToken();
    return axiosDocsInstance
      .get("counterparts", {
        cancelToken: cancelSource.token,
        params,
      })
      .then((response) => {
        return response.data;
      });
  },
};
