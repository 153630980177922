<template>
  <ValidationObserver ref="formValidationReporting" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні профілю СПФМ!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Керівник"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Призвіще"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="head_last_name"
        >
          <b-form-group label="Призвіще:">
            <b-form-input
              type="text"
              v-model="form.head_last_name"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Імʼя"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="head_first_name"
        >
          <b-form-group label="Імʼя:">
            <b-form-input
              type="text"
              v-model="form.head_first_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="По батькові"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="head_middle_name"
        >
          <b-form-group label="По батькові:">
            <b-form-input
              type="text"
              v-model="form.head_middle_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Посада"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="head_position"
        >
          <b-form-group label="Посада:">
            <b-form-input
              type="text"
              v-model="form.head_position"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          label="Наказ:"
          label-size="md"
          label-class="font-weight-bold"
        >
          <ValidationProvider
            name="Дата"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="head_date"
          >
            <b-form-group label="Дата:">
              <b-form-input
                type="date"
                v-model="form.head_date"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="Номер"
            rules="max:255"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="head_number"
          >
            <b-form-group label="Номер:">
              <b-form-input
                type="text"
                v-model="form.head_number"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                maxlength="255"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form-group>
      </b-form-group>
      <hr />
      <b-form-group
        label="Відповідальний ФМ"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Призвіще"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="responsible_last_name"
        >
          <b-form-group label="Призвіще:">
            <b-form-input
              type="text"
              v-model="form.responsible_last_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Імʼя"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="responsible_first_name"
        >
          <b-form-group label="Імʼя:">
            <b-form-input
              type="text"
              v-model="form.responsible_first_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="По батькові"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="responsible_middle_name"
        >
          <b-form-group label="По батькові:">
            <b-form-input
              type="text"
              v-model="form.responsible_middle_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Посада"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="responsible_position"
        >
          <b-form-group label="Посада:">
            <b-form-input
              type="text"
              v-model="form.responsible_position"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          label="Наказ:"
          label-size="md"
          label-class="font-weight-bold"
        >
          <ValidationProvider
            name="Дата"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="responsible_date"
          >
            <b-form-group label="Дата:">
              <b-form-input
                type="date"
                v-model="form.responsible_date"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="Номер"
            rules="max:255"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="responsible_number"
          >
            <b-form-group label="Номер:">
              <b-form-input
                type="text"
                v-model="form.responsible_number"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                maxlength="255"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form-group>
      </b-form-group>
      <hr />
      <b-form-group
        label="т.в.о. Відповідального ФМ"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Призвіще"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="deputy_responsible_last_name"
        >
          <b-form-group label="Призвіще:">
            <b-form-input
              type="text"
              v-model="form.deputy_responsible_last_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Імʼя"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="deputy_responsible_first_name"
        >
          <b-form-group label="Імʼя:">
            <b-form-input
              type="text"
              v-model="form.deputy_responsible_first_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="По батькові"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="deputy_responsible_middle_name"
        >
          <b-form-group label="По батькові:">
            <b-form-input
              type="text"
              v-model="form.deputy_responsible_middle_name"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Посада"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="deputy_responsible_position"
        >
          <b-form-group label="Посада:">
            <b-form-input
              type="text"
              v-model="form.deputy_responsible_position"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
              maxlength="255"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          label="Наказ:"
          label-size="md"
          label-class="font-weight-bold"
        >
          <ValidationProvider
            name="Дата"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="deputy_responsible_date"
          >
            <b-form-group label="Дата:">
              <b-form-input
                type="date"
                v-model="form.deputy_responsible_date"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            name="Номер"
            rules="max:255"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="deputy_responsible_number"
          >
            <b-form-group label="Номер:">
              <b-form-input
                type="text"
                v-model="form.deputy_responsible_number"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                maxlength="255"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-form-group>
      </b-form-group>

      <ValidationProvider
        name="Код СПФМ"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="code_id"
      >
        <b-form-group label="Код СПФМ:">
          <b-form-select
            v-model="form.code_id"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          >
            <option :value="null"></option>
            <option
              v-for="option in financialModelsCodes"
              :key="option.id"
              :value="option.id"
            >
              {{ option.value + " - " + option.name }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        name="Вид послуги (Н020)"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="h020"
      >
        <b-form-group label="Вид послуги (Н020):">
          <multiselect
            v-model="form.h020"
            :multiple="true"
            :options="h020Options.map((e) => e.id)"
            :custom-label="customH020Label"
            placeholder="Вибір діяльності"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="звіти"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="reports"
      >
        <b-form-group label="Звіти:">
          <multiselect
            v-model="form.reports"
            :options="[
              {
                all: 'Всі',
                options: reports.map((e) => e.value),
              },
            ]"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            group-values="options"
            group-label="all"
            :group-select="true"
            :show-labels="false"
            placeholder=""
            :custom-label="customReportLabel"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          >
            <template class="checkbox-label" v-slot:option="scope">
              <input
                v-if="!scope.option.hasOwnProperty('$groupLabel')"
                type="checkbox"
                :checked="form.reports.includes(scope.option)"
                @focus.prevent
              />
              <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                {{ scope.option.$groupLabel }}
              </template>
              <template v-else>
                {{ customReportLabel(scope.option) }}
              </template>
            </template>
          </multiselect>
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
        type="submit"
        size="sm"
        variant="success"
      >
        <i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>
  </ValidationObserver>
</template>
<script>
import Multiselect from "vue-multiselect";
import mixins from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "ReportingProfile",
  props: ["financialModel"],
  mixins: [mixins],
  components: { Multiselect },
  data() {
    return {
      form: {
        id: null,
        head_last_name: "",
        head_first_name: "",
        head_middle_name: "",
        head_position: "",
        head_date: "",
        head_number: "",
        responsible_last_name: "",
        responsible_first_name: "",
        responsible_middle_name: "",
        responsible_position: "",
        responsible_date: "",
        responsible_number: "",
        deputy_responsible_last_name: "",
        deputy_responsible_first_name: "",
        deputy_responsible_middle_name: "",
        deputy_responsible_position: "",
        deputy_responsible_date: "",
        deputy_responsible_number: "",
        code_id: null,
        h020: [],
        reports: [],
      },
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      h020Options: "dictionary/allH020",
      financialModelsCodes: "dictionary/allFinancialModelsCodes",
      reports: "dictionary/allReports",
    }),
  },
  watch: {
    financialModel: {
      deep: true,
      handler(value) {
        this.form.id = value.id;
        this.form.head_last_name = value.head_last_name || "";
        this.form.head_first_name = value.head_first_name || "";
        this.form.head_middle_name = value.head_middle_name || "";
        this.form.head_position = value.head_position || "";
        this.form.head_date = value.head_date || "";
        this.form.head_number = value.head_number || "";
        this.form.responsible_last_name = value.responsible_last_name || "";
        this.form.responsible_first_name = value.responsible_first_name || "";
        this.form.responsible_middle_name = value.responsible_middle_name || "";
        this.form.responsible_position = value.responsible_position || "";
        this.form.responsible_date = value.responsible_date || "";
        this.form.responsible_number = value.responsible_number || "";
        this.form.deputy_responsible_last_name =
          value.deputy_responsible_last_name || "";
        this.form.deputy_responsible_first_name =
          value.deputy_responsible_first_name || "";
        this.form.deputy_responsible_middle_name =
          value.deputy_responsible_middle_name || "";
        this.form.deputy_responsible_position =
          value.deputy_responsible_position || "";
        this.form.deputy_responsible_date = value.deputy_responsible_date || "";
        this.form.deputy_responsible_number =
          value.deputy_responsible_number || "";
        this.form.code_id = value.code?.id || null;
        this.form.h020 = value.h020?.map((e) => e.id);
        this.form.reports = value.reports ?? [];

        requestAnimationFrame(() => {
          this.$refs.formValidationReporting.reset();
        });
      },
    },
  },
  methods: {
    customH020Label(id) {
      const option = this.h020Options.find((e) => e.id === id);

      return `${option.value}  ${option.name}`;
    },
    customReportLabel(value) {
      const groupBy = this.reports.find((e) => e.value === value);
      return groupBy.name;
    },
    onSubmit() {
      this.errors = {};

      this.$store
        .dispatch(
          "financialModel/update",
          this.getChangedFields(
            this.$refs.formValidationReporting.fields,
            this.form
          )
        )
        .catch((response) => {
          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidationReporting.setErrors(this.errors);
        });
    },
  },
};
</script>
