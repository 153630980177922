<template>
  <b-modal
    id="export-counterpart-modal"
    title="Експорт контрагентів"
    hide-footer
    @show="refreshModal()"
    @hidden="refreshModal()"
  >
    <error-alert
      :errors="errors"
      message="Виникла помилка експорта контрагентів!"
    />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="тип експорту контрагентів"
          :rules="{ required: true }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Оберіть експорт який вам потрібен">
            <b-form-select
              text-field="text"
              value-field="route"
              v-model="selectedOptionRoute"
              :options="exportOptions"
              :state="errors.length > 0 ? false : dirty ? true : null"
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div slot="footer">
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="submitting"
          >
            Підтвердити
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert.vue";
import api from "@/api/api";

export default {
  name: "CounterpartExportModal",
  props: {
    propFilters: {
      type: Object,
      required: true,
    },
  },
  components: { ErrorAlert },
  computed: {
    exportOptions: function () {
      return [
        {
          text: "Експорт всіх контрагентів",
          route: "list",
        },
        {
          text: "Форма 4",
          route: "form4",
        },
      ];
    },
  },
  data() {
    return {
      request: null,
      selectedOptionRoute: null,
      submitting: false,
      errors: {},
    };
  },
  methods: {
    refreshModal() {
      this.selectedOptionRoute = null;
      this.errors = {};
    },
    onSubmit() {
      this.submitting = true;
      this.errors = {};
      this.request = api.exportCounterparts(
        this.selectedOptionRoute,
        this.propFilters
      );
      this.request
        .then(() => {
          this.$snotify.success("Файл буде надіслано на вашу пошту.");
          this.$bvModal.hide("export-counterpart-modal");
        })
        .catch((error) => {
          this.$snotify.error("Помилка експорта операцій");
          this.errors = {
            code: error.response.status,
          };
        })
        .finally(() => (this.submitting = false));
    },
  },
};
</script>
