import { axiosRepsInstance } from "@/api/repsApi";

export default {
  async exportCounterparts(route, filters) {
    return axiosRepsInstance
      .post("exports/counterparts/" + route, null, { params: filters })
      .then((response) => {
        return response;
      });
  },
  async printCounterpart(id, params) {
    return axiosRepsInstance
      .get("print/counterpart/" + id, {
        params,
      })
      .then((response) => {
        return response;
      });
  },
};
