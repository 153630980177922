import storage from "../../api/api";
import {
  getField,
  updateField
} from "./../../../node_modules/vuex-map-fields/src/index";

export const state = {
  items: [],
  page: 1,
  count: 20,
  total: 0,
  counterparts: [],
  filter: {}
};

export const getters = {
  getField,
  getFilters: state => {
    return {
      ...state.filter
    };
  },
  getAllContracts: state => {
    return state.items;
  },
  getAllCounterparts: state => {
    return state.counterparts;
  },
  totalItems: state => {
    return state.total;
  }
};

export const actions = {
  async add({ commit }, form) {
    await storage
      .createNewContractItem(form.counterpart_id, form)
      .then(item => {
        commit("push", item.data);
      });
  },
  setContracts({ commit }) {
    let config = {
      params: {
        page: state.page,
        count: state.count,
        ...state.filter
      }
    };
    commit("clearTable");

    return storage
      .getContractItems(config.params)
      .then(response => commit("setContracts", response));
  },
  pullCounterparts({ commit }) {
    return storage.getContractsCounterparts().then(({ data }) => {
      commit("clearCounterparts");
      data.forEach(counterpart => commit("setCounterpart", counterpart));
    });
  },
  deleteItem({ commit }, itemId) {
    storage
      .removeContract(itemId)
      .then(() => commit("removeItemFromPool", itemId))
      .then(() => this._vm.$snotify.success("Видалено успішно"))
      .catch(({ response }) => {
        this._vm.$snotify.error(response.data.description);
        return response.data;
      });
  },
  restoreItem({ commit }, itemId) {
    storage
      .restoreContract(itemId)
      .then(item => {
        commit("removeItemFromPool", itemId);
        commit("push", item.data);
      })
      .then(() => this._vm.$snotify.success("Відновлено успішно"));
  }
};

export const mutations = {
  push: (state, item) => state.items.unshift(item),
  updateField,
  setCounterpart(state, counterpart) {
    state.counterparts.push(counterpart);
  },
  setFilter(state, filterObj) {
    state.filter = {};
    for (let key in filterObj) {
      if (
        filterObj[key].value !== undefined &&
        filterObj[key].value.length === 0
      ) {
        continue;
      }
      state.filter[
        "filter[" + filterObj[key].name + "@" + filterObj[key].type + "]"
      ] = filterObj[key].value;
    }
  },
  setContracts(state, response) {
    state.items = response.data.map(item => {
      return Object.assign(item);
    });
    state.total = response.meta.total;
  },
  removeItemFromPool(state, itemId) {
    state.items.map((item, key) => {
      if (item.id === itemId) {
        state.items.splice(key, 1);
      }
    });
  },
  clearCounterparts(state) {
    state.counterparts = [];
  },
  clearTable(state) {
    state.items = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
