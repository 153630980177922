<template>
  <ValidationObserver ref="formValidationLicense" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">Виникла помилка при збереженні ліцензії!</h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="Ліцензія"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Найменування"
          :rules="{ required: true, max: 255 }"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="name"
        >
          <b-form-group label="Найменування:">
            <b-form-input
              type="text"
              v-model="form.name"
              maxlength="255"
              placeholder="Ліцензія №123 від 01.01.2022"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Дата початку"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="started_at"
          :rules="{ required: true }"
        >
          <b-form-group label="Дата початку">
            <b-form-input
              type="date"
              v-model="form.started_at"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Дата закінчення"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="ended_at"
        >
          <b-form-group label="Дата закінчення">
            <b-form-input
              type="date"
              v-model="form.ended_at"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <div slot="footer">
        <b-button
          type="submit"
          size="sm"
          :variant="propLicense ? 'primary' : 'success'"
        >
          <i class="fa fa-dot-circle-o"></i>
          {{ propLicense ? "Зберегти" : "Додати" }}
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "LicenseForm",
  mixins: [mixins],
  props: {
    propLicense: {
      type: Object | null,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      form: {
        id: null,
        name: "",
        started_at: "",
        ended_at: "",
      },
    };
  },
  created() {
    Object.assign(this.form, this.propLicense);
  },
  methods: {
    onSubmit() {
      let fields = this.getChangedFields(
        this.$refs.formValidationLicense.fields,
        this.form
      );

      fields.financial_model_id = this.$route.params.id;
      this.errors = {};

      this.$store
        .dispatch(
          this.propLicense
            ? "financialModel/updateLicense"
            : "financialModel/createLicense",
          fields
        )
        .then(() => {
          this.$root.$emit("bv::hide::modal", "modalLicense");
        })
        .catch(({ response }) => {
          let message = this.propLicense
            ? "Виникла помилка при створенні ліцензії"
            : "Виникла помилка при оновленні данних ліцензії";

          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidationLicense.setErrors(this.errors);
          this.$snotify.error(message);
        });
    },
  },
};
</script>
