export default {
  taskType: {
    name: "taskType",
    type: "where",
    value: "",
  },
  counterpartType: {
    name: "type_id",
    type: "whereIn",
    value: [],
  },
  identificationStatus: {
    name: "profileMaintain.identification_status_id",
    type: "whereIn",
    value: [],
  },
  currentCrimeRiskLevel: {
    name: "riskManagement.crimeRisks.current_risk_level_id",
    type: "whereIn",
    value: [],
  },
  includeIds: {
    name: "id",
    type: "whereIn",
    value: [],
  },
  excludeIds: {
    name: "id",
    type: "whereNotIn",
    value: [],
  },
  isPublic: {
    name: "clarification.is_public",
    type: "where",
    value: "",
  },
  financialModel: {
    name: "financial_model_id",
    type: "where",
    value: "",
  },
};
