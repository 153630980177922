export default {
  issuedFrom: {
    name: "issued_date",
    type: "gte",
    value: "",
  },
  issuedTo: {
    name: "issued_date",
    type: "lte",
    value: "",
  },
  contractType: {
    name: "type.value",
    type: "where",
    value: "",
  },
  id: {
    name: "id",
    type: "where",
    value: "",
  },
  contractNumber: {
    name: "number",
    type: "where",
    value: "",
  },
  counterpart_id: {
    name: "counterpart_id",
    type: "where",
    value: "",
  },
  status: {
    name: "state.value",
    type: "where",
    value: "",
  },
  cPartId: {
    value: "",
    virtualValue: null,
  },
  financialModel: {
    name: "financial_model_id",
    type: "whereIn",
    value: [],
  },
  withDeleted: {
    name: "deleted_at",
    type: "withTrashed",
    value: "",
  },
  destinationCode: {
    name: "destination_code_id",
    type: "where",
    value: "",
  },
  alias: {
    name: "paymentDirections.name",
    type: "where",
    value: "",
  },
};
