export default {
  id: {
    name: "id",
    type: "where",
    value: "",
  },
  edrpou: {
    name: "code",
    type: "where",
    value: "",
  },
  fullName: {
    name: "name",
    type: "ilike",
    value: "",
  },
  dateFrom: {
    name: "created_at",
    type: "gte",
    value: "",
  },
  dateTo: {
    name: "created_at",
    type: "lte",
    value: "",
  },
  identificationStatus: {
    name: "profileMaintain.identification_status_id",
    type: "whereIn",
    value: [],
  },
  verificationMethod: {
    name: "profileMaintain.verification_method_id",
    type: "whereIn",
    value: [],
  },
  currentCrimeRiskLevel: {
    name: "riskManagement.crimeRisks.current_risk_level_id",
    type: "whereIn",
    value: [],
  },
  identFrom: {
    name: "profileMaintain.identification_date",
    type: "gte",
    value: "",
  },
  identTo: {
    name: "profileMaintain.identification_date",
    type: "lte",
    value: "",
  },
  contract: {
    name: "contracts.type.value",
    type: "where",
    value: "",
  },
  counterpartType: {
    name: "type_id",
    type: "whereIn",
    value: [],
  },
  isPublic: {
    name: "clarification.is_public",
    type: "where",
    value: "",
  },
  withDeleted: {
    name: "deleted_at",
    type: "withTrashed",
    value: "",
  },
  destinationCode: {
    name: "contracts.destination_code_id",
    type: "where",
    value: "",
  },
  site: {
    name: "riskManagement.site",
    type: "ilike",
    value: "",
  },
  financialModel: {
    name: "financial_model_id",
    type: "whereIn",
    value: [],
  },
  status_id: {
    name: "status_id",
    type: "whereIn",
    value: [],
  },
};
