import storage from "../../api/api";
import {
  getField,
  updateField,
} from "./../../../node_modules/vuex-map-fields/src/index";

export const state = {
  items: [],
  page: 1,
  count: 20,
  total: 0,
  kved: [],
  editItem: {},
  isChanged: "",
  filter: {},
};

export const getters = {
  getField,
  getFilters: (state) => {
    return {
      ...state.filter,
    };
  },
  getAllCounterparts: (state) => {
    return state.items;
  },
  totalItems: (state) => {
    return state.total;
  },
};

export const actions = {
  async add({ commit }, form) {
    await storage.createCounterpart(form).then((item) => {
      commit("push", item.data);
    });
  },
  setCounterparts({ commit }) {
    let config = {
      params: {
        page: state.page,
        count: state.count,
        ...state.filter,
      },
    };
    commit("clearTable");

    return storage
      .getCounterpartItems(config.params)
      .then((response) => commit("setCounterparts", response));
  },
  deleteItem({ commit }, itemId) {
    storage
      .removeCounterpart(itemId)
      .then(() => commit("removeItemFromPool", itemId))
      .then(() => this._vm.$snotify.success("Видалено успішно"));
  },
  restoreItem({ commit }, itemId) {
    storage
      .restoreCounterpart(itemId)
      .then((item) => {
        commit("removeItemFromPool", itemId);
        commit("push", item.data);
      })
      .then(() => this._vm.$snotify.success("Відновлено успішно"));
  },
  print(context, [itemId, $params]) {
    storage
      .printCounterpart(itemId, $params)
      .then(() =>
        this._vm.$snotify.success("Файл буде надіслано на вашу пошту")
      )
      .catch(() => this._vm.$snotify.error("Сталася помилка"));
  },
};

export const mutations = {
  push: (state, item) => state.items.unshift(item),
  updateField,
  setCounterparts(state, response) {
    state.items = response.data.map((item) => {
      return Object.assign(item);
    });
    state.total = response.meta.total;
  },
  setFilter(state, filterObj) {
    state.filter = {};
    for (let key in filterObj) {
      if (filterObj[key].value.length === 0) {
        continue;
      }
      state.filter[
        "filter[" + filterObj[key].name + "@" + filterObj[key].type + "]"
      ] = filterObj[key].value;
    }
  },
  removeItemFromPool(state, itemId) {
    state.items.map((item, key) => {
      if (item.id === itemId) {
        state.items.splice(key, 1);
      }
    });
  },
  clearTable(state) {
    state.items = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
