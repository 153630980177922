<template>
  <b-form-group label="Рахунки" label-size="lg" label-class="font-weight-bold">
    <b-table
      :fields="tableFields"
      :hover="true"
      :outlined="true"
      :fixed="true"
      :small="true"
      :items="accounts"
    >
      <template v-slot:cell(actions)="data">
        <b-button
          variant="ghost-info link"
          @click="editAccount(data.index)"
          size="sm"
        >
          <i class="fa fa-edit" />
        </b-button>

        <b-button
          v-if="
            $auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))
          "
          variant="link text-danger"
          size="sm"
          @click="removeAccount(data.index)"
        >
          <i class="fa fa-close" />
        </b-button>
      </template>
    </b-table>

    <b-button
      v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
      variant="link text-success"
      size="sm"
      @click="createAccount()"
      class="mb-2"
    >
      <i class="fa fa-plus" />
    </b-button>

    <b-modal id="modalAccount" hide-header hide-footer size="lg">
      <account-form :prop-account="account" />
    </b-modal>
  </b-form-group>
</template>
<script>
import AccountForm from "@/entity/FinancialModels/forms/AccountForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "Accounts",
  components: { AccountForm },
  data() {
    return {
      account: null,
      tableFields: [
        { key: "bank", label: "Банк" },
        { key: "bank_code", label: "МФО" },
        { key: "iban", label: "Рахунок" },
        { key: "started_at", label: "Дата початку" },
        { key: "ended_at", label: "Дата закінчення" },
        this.$auth.can(
          this.$stringConstants("PERMISSION_CREATE_FINANCIAL_MODEL")
        )
          ? { key: "actions", label: "Дії" }
          : null,
      ],
    };
  },
  computed: {
    ...mapGetters({
      accounts: "financialModel/getAccounts",
    }),
  },
  methods: {
    createAccount() {
      this.account = null;
      this.$root.$emit("bv::show::modal", "modalAccount");
    },
    editAccount(index) {
      this.account = this.accounts[index];
      this.$root.$emit("bv::show::modal", "modalAccount");
    },
    removeAccount(index) {
      if (this.accounts[index].id) {
        if (this.accounts.length === 1) {
          this.$snotify.error(
            "Видалення заборонено. Профіль СПФМ повинен мати хоча б 1 рахунок"
          );
        } else if (confirm("Ви впевнені що хочете видалити цей запис?"))
          this.$store.dispatch(
            "financialModel/deleteAccount",
            this.accounts[index]
          );
      }
    },
  },
};
</script>
