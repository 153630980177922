<template>
  <b-form-group label="Ліцензії" label-size="lg" label-class="font-weight-bold">
    <b-table
      :fields="tableFields"
      :hover="true"
      :outlined="true"
      :fixed="true"
      :small="true"
      :items="licenses"
    >
      <template v-slot:cell(actions)="data">
        <b-button
          variant="ghost-info link"
          @click="editLicense(data.index)"
          size="sm"
        >
          <i class="fa fa-edit" />
        </b-button>

        <b-button
          v-if="
            $auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))
          "
          variant="link text-danger"
          size="sm"
          @click="removeLicense(data.index)"
        >
          <i class="fa fa-close" />
        </b-button>
      </template>
    </b-table>

    <b-button
      v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
      variant="link text-success"
      size="sm"
      @click="createLicense()"
      class="mb-2"
    >
      <i class="fa fa-plus" />
    </b-button>

    <b-modal id="modalLicense" hide-header hide-footer size="lg">
      <license-form :prop-license="license" />
    </b-modal>
  </b-form-group>
</template>
<script>
import { mapGetters } from "vuex";
import LicenseForm from "@/entity/FinancialModels/forms/LicenseForm.vue";

export default {
  name: "Licenses",
  components: { LicenseForm },
  data() {
    return {
      license: null,
      tableFields: [
        { key: "name", label: "Назва" },
        { key: "started_at", label: "Дата початку" },
        { key: "ended_at", label: "Дата закінчення" },
        this.$auth.can(
          this.$stringConstants("PERMISSION_CREATE_FINANCIAL_MODEL")
        )
          ? { key: "actions", label: "Дії" }
          : null,
      ],
    };
  },
  computed: {
    ...mapGetters({
      licenses: "financialModel/getLicenses",
    }),
  },
  methods: {
    createLicense() {
      this.license = null;
      this.$root.$emit("bv::show::modal", "modalLicense");
    },
    editLicense(index) {
      this.license = this.licenses[index];
      this.$root.$emit("bv::show::modal", "modalLicense");
    },
    removeLicense(index) {
      if (this.licenses[index].id) {
        if (confirm("Ви впевнені що хочете видалити цей запис?")) {
          this.$store.dispatch(
            "financialModel/deleteLicense",
            this.licenses[index]
          );
        }
      }
    },
  },
};
</script>
