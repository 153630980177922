<template>
  <b-modal
    id="export-operations-modal"
    title="Вивантаження операцій"
    hide-footer
    @show="refreshModal()"
    @hidden="refreshModal()"
  >
    <error-alert
      :errors="errors"
      message="Виникла помилка експорта операцій!"
    />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="тип експорту операцій"
          :rules="{ required: true }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Оберіть експорт який вам потрібен">
            <b-form-select
              v-model="selectedOptionRoute"
              :state="errors.length > 0 ? false : dirty ? true : null"
            >
              <option
                v-for="option in exportOptions"
                v-if="option.can"
                :key="option.id"
                :value="option.route"
              >
                {{ option.text }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="розріз операцій"
          :rules="{ required: true }"
          v-slot="{ errors, dirty }"
          v-if="enabledOptionGroupBy"
        >
          <b-form-group label="Оберіть розрізи які потрібні">
            <multiselect
              v-model="selectedOptionGroupBy"
              :options="[
                {
                  all: 'Всі',
                  options: groupBy.map((e) => e.value),
                },
              ]"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              group-values="options"
              group-label="all"
              :group-select="true"
              :show-labels="false"
              placeholder=""
              :custom-label="customLabel"
              :state="errors.length > 0 ? false : dirty ? true : null"
            >
              <template class="checkbox-label" v-slot:option="scope">
                <input
                  v-if="!scope.option.hasOwnProperty('$groupLabel')"
                  type="checkbox"
                  :checked="selectedOptionGroupBy.includes(scope.option)"
                  @focus.prevent
                />
                <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                  {{ scope.option.$groupLabel }}
                </template>
                <template v-else>
                  {{ customLabel(scope.option) }}
                </template>
              </template>
            </multiselect>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="розділити результат"
          :rules="{ regex: /^(default|day|month|quarter)$/ }"
          v-slot="{ errors, dirty }"
        >
          <b-form-group label="Розділити результат">
            <b-form-select
              text-field="text"
              value-field="value"
              v-model="selectedOptionPeriod"
              :options="exportPeriods"
              :disabled="disabledOptionPeriod"
              :state="errors.length > 0 ? false : dirty ? true : null"
            >
            </b-form-select>
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <div slot="footer">
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="submitting"
          >
            Підтвердити
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert.vue";
import api from "@/api/api";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "OperationExportModal",
  props: {
    propFilters: {
      type: Object,
      required: true,
    },
  },
  components: { Multiselect, ErrorAlert },
  computed: {
    ...mapGetters({
      me: "auth/me",
    }),
    exportOptions: function () {
      const options = [
        {
          text: "По валютам і країнам",
          route: "currency-country",
          can: this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR"),
        },
        {
          text: "Форма 1",
          route: "form1",
          can: true,
        },
        {
          text: "Форма 1 (інспекційна перевірка НБУ)",
          route: "form1-inspection",
          can: true,
        },
        {
          text: "Форма 2",
          route: "form2",
          can: true,
        },
        {
          text: "Форма 2 (для податкової)",
          route: "form2-tax-office",
          can: this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR"),
        },
        {
          text: "Форма 3",
          route: "form3",
          can: true,
        },
        {
          text: "Форма 3 (платник)",
          route: "form3-payer",
          can: this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR"),
        },
        {
          text: "Форма групування по банківським терміналам",
          route: "form-terminal",
          can: this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR"),
        },
        {
          text: "Форма 5",
          route: "form5",
          can: true,
        },
        {
          text: "Реєстр платіжних операцій (інспекційна перевірка НБУ)",
          route: "payment-operations-registry",
          can: true,
        },
        {
          text: "Розріз за банками (інспекційна перевірка НБУ)",
          route: "breakdown-by-banks",
          can: true,
        },
        {
          text: "Розріз за кодом призначення (для перевірки НБУ)",
          route: "operation-destination-code",
          can: true,
        },
        {
          text: "Зарахування/Виплата коштів (для перевірки НБУ)",
          route: "enrollment-disbursement-of-funds",
          can: true,
        },
        {
          text: "Експорт всіх операцій",
          route: "list",
          can: this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR"),
        },
      ];

      return options;
    },
    exportPeriods: function () {
      const options = [
        {
          text: "Без розділення",
          value: "default",
        },
        {
          text: "По дням",
          value: "day",
        },
        {
          text: "По місяцям",
          value: "month",
        },
        {
          text: "По кварталам",
          value: "quarter",
        },
      ];

      return options;
    },
    groupBy: function () {
      if (this.selectedOptionRoute === "form-terminal") {
        return [
          {
            text: "за назвою терміналу (платіжна система)",
            value: "terminal_name",
          },
          {
            text: "за ID банківського терміналу",
            value: "bank_terminal_id",
          },
        ];
      } else {
        return [
          {
            text: "за номером банківського рахунку (IBAN)",
            value: "iban",
          },
          {
            text: "за назвою терміналу (платіжна система)",
            value: "terminal_name",
          },
          {
            text: "за назвою банку (еквайр)",
            value: "bank_name",
          },
        ];
      }
    },
  },
  data() {
    return {
      selectedOptionRoute: null,
      selectedOptionPeriod: "default",
      disabledOptionPeriod: false,
      enabledOptionGroupBy: false,
      selectedOptionGroupBy: [],
      submitting: false,
      errors: {},
    };
  },
  methods: {
    refreshModal() {
      this.selectedOptionRoute = null;
      this.selectedOptionPeriod = "default";
      this.selectedOptionGroupBy = [];
      this.disabledOptionPeriod = false;
      this.enabledOptionGroupBy = false;
      this.errors = {};
    },
    onSubmit() {
      this.submitting = true;
      this.errors = {};
      api
        .exportOperations(this.selectedOptionRoute, this.propFilters)
        .then(() => {
          this.$snotify.success("Експорт в обробці.");
          this.$bvModal.hide("export-operations-modal");
        })
        .catch((error) => {
          this.$snotify.error("Помилка експорта операцій");
          this.errors = {
            code: error.response.status,
            description:
              error.response.status === 422
                ? error.response.data.errors
                : error.response.statusText,
          };
        })
        .finally(() => (this.submitting = false));
    },
    customLabel(value) {
      const groupBy = this.groupBy.find((e) => e.value === value);
      return groupBy.text;
    },
  },
  watch: {
    selectedOptionPeriod(newVal) {
      this.propFilters.export_period = newVal;
    },
    selectedOptionGroupBy(newVal) {
      this.propFilters.group_by = newVal.toString();
    },
    selectedOptionRoute(newVal) {
      const validForms = ["form3", "form-terminal"];

      if (validForms.includes(newVal)) {
        this.enabledOptionGroupBy = true;
        this.selectedOptionGroupBy =
          newVal === "form3" ? ["iban"] : ["terminal_name"];
      } else {
        this.enabledOptionGroupBy = false;
        delete this.propFilters.group_by;
        this.selectedOptionGroupBy = [];
      }
      if (newVal === "enrollment-disbursement-of-funds") {
        this.selectedOptionPeriod = "default";
        this.disabledOptionPeriod = true;
      } else {
        this.disabledOptionPeriod = false;
      }
    },
  },
};
</script>
