<template>
  <ValidationObserver ref="formValidation" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні профілю СПФМ!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        name="ЄДРПОУ"
        :rules="{ required: true, regex: /^[\d]{8}$/ }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="usreou_code"
      >
        <b-form-group label="ЄДРПОУ:">
          <b-form-input
            type="text"
            v-model="form.usreou_code"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
            maxlength="8"
            placeholder="8-ми значний номер"
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="Тип субʼєкта"
        rules="required"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="type_id"
      >
        <b-form-group label="Тип субʼєкта">
          <b-form-select
            text-field="name"
            value-field="id"
            v-model="form.type_id"
            :options="types"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-form-group
        label="Назва"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="назва"
          rules="required|max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="name"
        >
          <b-form-group label="Повна:">
            <b-form-input
              type="text"
              v-model="form.name"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          name="Скорочена"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="short_name"
        >
          <b-form-group label="Скорочена:">
            <b-form-input
              type="text"
              v-model="form.short_name"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <b-form-group
        label="Рахунок"
        label-size="lg"
        label-class="font-weight-bold"
      >
        <ValidationProvider
          name="Банк"
          rules="max:255"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="bank"
        >
          <b-form-group label="Банк:">
            <b-form-input
              type="text"
              v-model="form.bank"
              maxlength="255"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          name="Рахунок"
          :rules="{ required: true, regex: /^[A-Z]{2}[\d]{27}$/ }"
          v-slot="{ errors, dirty, validated, valid, changed }"
          vid="iban"
        >
          <b-form-group
            label="Номер рахунку:"
            description="UA000000000000000000000000000"
          >
            <b-form-input
              type="text"
              v-model="form.iban"
              maxlength="29"
              :state="
                setValidationState(errors, dirty, validated, valid, changed)
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-form-group>
      <ValidationProvider
        name="адреса електронної пошти"
        rules="email"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="email_support"
      >
        <b-form-group
          label="Адреса електронної пошти:"
          description="no-reply@example.com"
        >
          <b-form-input
            type="email"
            v-model="form.email_support"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="номер телефону"
        :rules="{ regex: /^\+[\d]{12}$/ }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="phone_support"
      >
        <b-form-group label="Номер телефону:" description="+380960000000">
          <b-form-input
            type="text"
            v-model="form.phone_support"
            maxlength="13"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="web посилання"
        :rules="{
          regex:
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          max: 255,
        }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="site"
      >
        <b-form-group label="WEB посилання:" description="www.example.com/uk">
          <b-form-input
            type="text"
            v-model="form.site"
            maxlength="255"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          />
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        name="звіти"
        :rules="{ required: true }"
        v-slot="{ errors, dirty, validated, valid, changed }"
        vid="reports"
      >
        <b-form-group label="Звіти:">
          <multiselect
            v-model="form.reports"
            :options="[
              {
                all: 'Всі',
                options: reports.map((e) => e.value),
              },
            ]"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            group-values="options"
            group-label="all"
            :group-select="true"
            :show-labels="false"
            placeholder=""
            :custom-label="customReportLabel"
            :state="
              setValidationState(errors, dirty, validated, valid, changed)
            "
          >
            <template class="checkbox-label" v-slot:option="scope">
              <input
                v-if="!scope.option.hasOwnProperty('$groupLabel')"
                type="checkbox"
                :checked="form.reports.includes(scope.option)"
                @focus.prevent
              />
              <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                {{ scope.option.$groupLabel }}
              </template>
              <template v-else>
                {{ customReportLabel(scope.option) }}
              </template>
            </template>
          </multiselect>
          <b-form-invalid-feedback
            :state="errors.length === 0"
            v-for="(error, index) in errors"
            v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_FINANCIAL_MODEL'))"
        type="submit"
        size="sm"
        variant="success"
      >
        <i class="fa fa-dot-circle-o"></i> Додати
      </b-button>
    </b-form>
  </ValidationObserver>
</template>

<script>
import mixins from "@/mixins";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  name: "FinancialModelForm",
  components: { Multiselect },
  mixins: [mixins],
  data() {
    return {
      errors: {},
      form: {
        usreou_code: "",
        type_id: "",
        name: "",
        short_name: "",
        bank: "",
        iban: "",
        email_support: "",
        phone_support: "",
        site: "",
        reports: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      types: "dictionary/allFinancialModelsTypes",
      reports: "dictionary/allReports",
    }),
  },
  methods: {
    onSubmit() {
      this.$store
        .dispatch(
          "financialModels/add",
          this.getChangedFields(this.$refs.formValidation.fields, this.form)
        )
        .then(() => {
          this.$parent.$parent.hide();
          this.$snotify.success("Збережено");
        })
        .catch(({ response }) => {
          this.errors = {};

          for (let variable in response.data.description) {
            this.errors[variable] = response.data.description[variable];
          }

          this.$refs.formValidation.setErrors(this.errors);
          this.$snotify.error("Не збережено");
        });
    },
    customReportLabel(value) {
      const groupBy = this.reports.find((e) => e.value === value);
      return groupBy.name;
    },
  },
};
</script>
